import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import logger from "../src/logger";

// An example of how you might use this hook:
/*
import useFeatureFlag from "./feature-flags/useFeatureFlag";

function MyComponent() {
  const myFeatureFlag = useFeatureFlag<boolean>("my-feature-flag");
 if (myFeatureFlag) {
   return <div>My feature flag is enabled!</div>;
 }
 return <div>My feature flag is not enabled.</div>;
}
*/

export type FFType = boolean | string | number | object;

export enum FeatureFlag {
  AGENCY_BILL_ACCOUNT_PAYABLES = "agency-bill-account-payables",
  AGENCY_BILL_DEPOSITS = "agency-bill-deposits",
  AGENCY_BILL_INVOICING = "agency-bill-invoicing",
  AMS_360_HEADLESS_DIRECT_BILL_POSTING = "ams-360-headless-direct-bill-posting",
  EPIC_HEADLESS_PAYABLE_POSTING = "epic-headless-payable-posting",
  OWL = "owl",
  CARRIER_PAYABLES_SHOW_FRONTING_OPTION = "carrier-payables-show-fronting-option",
  EMAIL_THREAD_CCS = "emails-in-threads",
  TRIAGE_SORT_BY_COMMISSION = "triage-sort-by-commission",
  JOURNAL_ENTRY_OWL = "JournalEntryOwl",
  TRIAGE_VISUAL_FIELD_COMPARISON = "triage-visual-field-comparison",
  ENABLE_FINALIZED_OUTBOUND_STATEMENT_STATUS = "statement-finalized-status-enabled",
  SHOW_INVOICE_SHEET = "show-invoice-sheet",
  STATEMENT_POSTING_JOURNAL_ENTRY_CREATION_ENABLED = "statement-posting-journal-entry-creation-enabled",
  ENABLE_AGR_REPORT = "enable-agr-report",
  ENABLE_STATEMENT_DETAIL_V3 = "enable-statement-detail-v3",
  HEADLESS_PAYABLE_CARRIER_PAYMENT_METHOD = "headless-payable-carrier-payment-method",
}

/**
 * A hook to retrieve a feature flag value from LaunchDarkly.
 * @param featureKey The key of the feature flag to retrieve.
 * @returns The value of the feature flag, or undefined if the feature flag is
 * not found.
 */
export default function useFeatureFlag<FFType>(
  featureKey: FeatureFlag
): FFType | undefined {
  const ldClient = useLDClient();
  const flags = useFlags();

  if (!ldClient) {
    // If you are finding your feature flags are returning undefined, but you
    // are sure that they are defined in LaunchDarkly, check that the
    // <FeatureFlagProvider> component is a parent of the component that is
    // calling useFeatureFlag.
    // If it is, this can be safely ignored, and is likely showing while the
    // Launch Darkly Client is still connecting.
    return undefined;
  }

  if (flags[featureKey.valueOf()] === undefined) {
    if (process.env.NODE_ENV === "development") {
      logger.error(
        `Feature flag "${featureKey}" not found.
			Check that feature flag "${featureKey}" exists in LaunchDarkly, and that the
			specified return type for the provided feature flag key matches the type
			respectively specified in LaunchDarkly, full flag object: ${JSON.stringify(
        flags
      )}`
      );
    }

    return undefined;
  }

  return flags[featureKey];
}
